import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import { config } from './config';

const { REACT_APP_CIRCLE_SHA1 } = process.env;

if (config.ENV !== 'local' && REACT_APP_CIRCLE_SHA1) {
  Sentry.init({
    dsn: config.SENTRY_UI_DSN,
    environment: config.ENV,
    release: `sushi@${REACT_APP_CIRCLE_SHA1.substring(0, 12)}`,
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
